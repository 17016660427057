import { useState } from "react";
import { useMainContext } from "../../../contexts/mainContext";
import CreateNewTeacherNotes from "./createNewTeacherNotes";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { UpdateSingleCourse } from "../functions/updateSingleCourse";
import EditTeacherNotes from "./editTeacherNotes";

const { confirm } = Modal;

const TeacherNotesPage = () => {
  const { singleCourseInfo, setSingleCourseInfo } = useMainContext();

  //openCreateModal
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>(null);

  //handleDeleteNote
  const handleDeleteNote = (selectedNote: any) => {
    console.log("___deleting:", selectedNote?.title);

    //filter out the note to be deleted
    const filteredNotes = singleCourseInfo?.teacherNotes?.filter(
      (note: any) => note?.title !== selectedNote?.title
    );

    let cooked_data: any = {
      _id: singleCourseInfo?._id,
      teacherNotes: filteredNotes,
    };

    console.log("___cooked_data:", cooked_data);

    UpdateSingleCourse(cooked_data)
      .then((response: any) => {
        console.log("newCourseRes:", response);
        if (response?.status === 200 || response?.status === 201) {
          setSingleCourseInfo(response?.data?.payload);
          message.success("Course information was upadted successfully!");
        } else {
          message.error(
            response?.data?.error ||
              "Error while updating course. Please try again"
          );
        }
      })
      .catch((error: any) => {
        console.log("newCourseErr:", error);
        message.error(
          error?.response?.data?.error ||
            "Error while updating course. Please try again"
        );
      });
  };

  //delete Confrim
  const showPromiseConfirm = (selectedNote: any) => {
    confirm({
      title: `Do you want to delete ${selectedNote?.title}?`,
      icon: <ExclamationCircleFilled />,
      content:
        "Please confirm to delete this note. This action cannot be undone",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        danger: true,
      },
      onOk() {
        return new Promise((resolve, reject) => {
          handleDeleteNote(selectedNote);
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log("Oops errors!"));
      },

      onCancel() {},
    });
  };

  return (
    <>
      {/* content */}
      <div className="mt-[20px] w-full">
        {/* add button */}
        <div className="flex justify-end">
          <button
            className="w-fit py-2 px-4 text-white bg-default_blue rounded-[30px] flex items-center gap-[10px]"
            onClick={() => setOpenCreateModal(true)}
          >
            <PlusIcon className="w-4 h-4" />
            Add New Note
          </button>
        </div>

        {/* notes list */}
        <div className="w-full mt-[20px] grid grid-cols-2 gap-[20px] items-center">
          {singleCourseInfo?.teacherNotes?.map((note: any, index: number) => (
            <div
              key={index}
              className="w-full bg-white shadow-sm p-[20px] rounded-lg col-span-1"
            >
              <p className="text-lg font-bold">{note?.title}</p>
              <p className="text-sm text-gray-500 line-clamp-4">
                {note?.description}
              </p>
              <div className="flex justify-end items-center gap-[10px] mt-[20px]">
                <button className="w-fit py-1 px-4 text-default_blue border-[1px] border-default_blue rounded-[30px] flex items-center gap-[10px]" onClick={() => {
                  setSelectedNote(note);
                  setOpenEditModal(true);
                }}>
                  <PencilIcon className="w-4 h-4" />
                  Edit
                </button>

                <button
                  className="w-fit py-1 px-4 text-red-500 border-[1px] border-red-500 rounded-[30px] flex items-center gap-[10px]"
                  onClick={() => showPromiseConfirm(note)}
                >
                  <TrashIcon className="w-4 h-4" />
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* create new notes modal */}
      {
        openCreateModal && <CreateNewTeacherNotes
        isOpened={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
      />
      }

      {/* edit notes modal */}
      {
        openEditModal && <EditTeacherNotes
        isOpened={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        selectedNote={selectedNote}
      />
      }

    </>

  );
};

export default TeacherNotesPage;
