import { useState } from "react";
import {
  Form,
  Input,
  message,
  Modal,
  notification,
  Progress,
  Spin
} from "antd";
import { PiCloudArrowUpLight } from "react-icons/pi";
import { global_variables } from "../../../helpers/globalVariables";
import BaseService from "../../../helpers/baseServices";
import { useMainContext } from "../../../contexts/mainContext";
import { encryptUrl } from "../functions/encryptUrl";
import { UpdateSingleCourse } from "../functions/updateSingleCourse";
import { useParams } from "react-router-dom";
import { IoVideocamOutline } from "react-icons/io5";


interface modalProps {
  isOpened: boolean;
  handleClose: () => void;
  handleReFetch?: () => void;
}

const CreateNewTeacherNotes = ({
  isOpened,
  handleClose,
}: modalProps) => {

  const { id } = useParams();
  const [form] = Form.useForm();
  const [isBusy, setIsBusy] = useState(false);
  const [noteVideo, setNoteVideo] = useState<any>("");
  const [imageLoading, setImageLoading] = useState(false);
  const [imgProgress, setImgProgress] = useState(25);

  const { setSingleCourseInfo, singleCourseInfo } = useMainContext();

  //handleImageUpload
  const handleImageUpload = async (file: any) => {
    if (file) {
      console.log("selectedVid::", file);
      setImageLoading(true);
      try {
        const fileFormat = file?.type?.split("/")[1];
        const uid = file?.uid;
        setImgProgress(65);

        const imgRes: any = await BaseService.file_upload(
          `${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
          file
        );
        console.log("imgRes", imgRes);
        setNoteVideo(imgRes?.data?.url);
        setImgProgress(95);
        setImageLoading(false);
        notification.success({
          message: "Success!",
          description: "Video uploaded successfully!",
        });
      } catch (error: any) {
        console.log("file uploading", error);
        message.error(
          error?.response?.data?.error ||
            "Error occured while upload video. Please retry"
        );
        setImageLoading(false);
      }
    }
  };

  //on form submit
  const onFinish = async (formValues: any) => {
    try {
      let cooked_data: any = {
        _id: id,
        teacherNotes: [
          {
            title: formValues?.title,
            description: formValues?.description,
            video: [{
              url: encryptUrl(noteVideo),
              language: "English",
            }],
          },
        ],
      };

      if(singleCourseInfo?.teacherNotes?.length > 0) {
        cooked_data.teacherNotes = [...singleCourseInfo.teacherNotes, {
          title: formValues?.title,
          description: formValues?.description,
          video: [{url: encryptUrl(noteVideo), language: "English"}]
        }]
      }

      console.log("formValues:", cooked_data);



      setIsBusy(true);

      await UpdateSingleCourse(cooked_data)
        .then((response: any) => {
          console.log("newCourseRes:", response);
          if (response?.status === 200 || response?.status === 201) {
            setSingleCourseInfo(response?.data?.payload);
            message.success("Course information was upadted successfully!");
            setIsBusy(false);
            form.resetFields();
            setNoteVideo("");
            handleClose();
          } else {
            message.error(
              response?.data?.error ||
                "Error while updating course. Please try again"
            );
            setIsBusy(false);
          }
        })
        .catch((error: any) => {
          console.log("newCourseErr:", error);
          message.error(
            error?.response?.data?.error ||
              "Error while updating course. Please try again"
          );
          setIsBusy(false);
        });
    } catch (error:any) {
      console.log("error:", error);
      message.error(
        error?.response?.data?.error ||
          "Error while updating course. Please try again"
      );
      setIsBusy(false);
    }
  };


  return (
    <>
      <Modal
        title="Create New Teacher Note"
        open={isOpened}
        onCancel={handleClose}
        footer={null}
        keyboard={false}
        maskClosable={false}
        closeIcon={!isBusy}
        centered
      >
        <hr className="mb-[20px]" />
        <div className="h-[450px] w-full px-[20px] overflow-y-auto">
          <Spin spinning={isBusy}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <div className="h-[160px] w-full mb-[40px]">
                  <label htmlFor="videoUpload" className="w-full">
                  <div className="w-full h-[160px] rounded-[5px] border-[2px] border-[#CCCCF8] border-dashed flex gap-2 items-center bg-[#F9F9FF] cursor-pointer">
                      {imageLoading ? (
                        <div className="w-full px-[40px]">
                          <Progress percent={imgProgress} showInfo />
                          <p className="animate-pulse">Uploading...</p>
                        </div>
                      ) : noteVideo ? (
                        <div className="w-full">
                          <video
                            className="h-[160px] rounded-[5px] w-full object-cover"
                            src={noteVideo}
                            controls
                          />
                        </div>
                      ) : (
                        <div className="w-full flex flex-col items-center justify-center text-text_primary">
                          <IoVideocamOutline className="h-[50px] w-[50px]" />
                          <p>Click to upload video</p>
                        </div>
                      )}
                    </div>
                  </label>
                  <input type="file" id="videoUpload" className="hidden" onChange={(e:any) => handleImageUpload(e?.target?.files[0])} />
                </div>

              <Form.Item className="mt-[20px]"
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please add a title!" }]}
              >
                <Input className="h-[40px]" placeholder="Ex: Robo Bundler" />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please add a description!" },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>

              <Form.Item>
                <button className="w-full h-[50px] rounded-[5px] bg-default_blue text-white flex gap-[5px] items-center justify-center">
                  <PiCloudArrowUpLight className="h-[20px] w-[20px]" />
                  <p>Publish Notes</p>
                </button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default CreateNewTeacherNotes;
