import CryptoJS from "crypto-js";
import { CRYPTO_KEY } from "../../../helpers/constants";

export function encryptUrl(url: string) {
    const encryptedData = CryptoJS.AES.encrypt(url, CRYPTO_KEY).toString();
    return encryptedData;
}

export function decryptUrl(encryptedData: string) {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, CRYPTO_KEY).toString(CryptoJS.enc.Utf8);
    return decryptedData;
}