import React, { useState } from 'react'
import PageLayout from '../../../components/Layouts/page-layout'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import EditCoursesPage from './editCoursePage'
import AssessmentExamsPage from '../assessmentExams/assessmentExamsPage'
import { useMainContext } from '../../../contexts/mainContext'
import CourseContentPage from '../courseContent/courseContentPage'
import TeacherNotesPage from '../teacherNotes/teacherNotesPage'


const EditCourseMainPage = () => {

    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(0)
    const { singleCourseInfo } = useMainContext()


    //defaultTabs
    const tabNames = [
        "Course Info", "Assessment Quiz", "Course Content", "Teacher Notes"
    ]

    //tabComponents
    const tabComponents = [
        <EditCoursesPage />,
        <AssessmentExamsPage />,
        <CourseContentPage />,
        <TeacherNotesPage />
    ]

    return (
        <>
            <PageLayout>
                <div className="m-4">
                    <div className="flex gap-[20px] items-center">
                        <button className='px-[20px] py-[8px] rounded-[30px] border-[1px] border-default_blue text-default_blue flex gap-[5px] items-center' onClick={() => navigate("/courses")}>
                            <IoArrowBackOutline className='h-[20px] w-[20px]' />
                            <p>Back</p>
                        </button>

                        <p className="text-2xl my-4 text-text_primary font-semibold line-clamp-1">
                            {singleCourseInfo?.title || "Edit Course"}
                        </p>
                    </div>

                    {/* content */}
                    <div className="mt-[20px] w-full gap-[20px]">
                        {/* tabs */}
                        <div className="flex gap-[10px] items-center">
                            {
                                tabNames.map((tab, i) => (
                                    <div key={i} className={`w-fit px-[20px] py-[8px] rounded-[30px] shadow-sm cursor-pointer hover:shadow-lg ${activeTab === i ? 'bg-gradient-to-r from-light_blue to-default_blue text-white' : 'bg-white text-text_primary'}`} onClick={() => setActiveTab(i)}>
                                        <p>{tab}</p>
                                    </div>
                                ))
                            }
                        </div>

                        {/* content */}
                        <div className='w-full mt-[30px]'>
                            {tabComponents[activeTab]}
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    )
}

export default EditCourseMainPage
